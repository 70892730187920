@import 'variables';

.footer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $blockT-color;
  background-color: $blockB-color;
  font-size: 16px;
  font-weight: 500;

  @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
    font-size: 14px;
  }
    
  @media (max-width: $breakpoint-mobile) {
    font-size: 12px;
  }

  p {
    font-weight: 700;
  }
  &__logos {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    gap: 20px;

    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
      font-size: 24px;
    }
      
    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
    }

    a {
      color: $blockT-color;
    }
    a:hover {
      color: $linkHover-color;
      opacity: 0.7;
    }
  }

  &__coord {
    display: flex;
    align-items: baseline;
    justify-items: space-around;
    gap: 80px;

    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
      gap: 50px;
    }
      
    @media (max-width: $breakpoint-mobile) {
      gap: 20px;
    }

    &__contact {
      font-size: 16px;
      list-style: none;
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 0;
      
      @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
      }
        
      @media (max-width: $breakpoint-mobile) {
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }

      &--title {
        font-weight: 700;
      }
    }
  }
}

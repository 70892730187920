/* ---------------------------- Style pour le composant COLLAPSE ---------------------------- */

@import 'variables';

.collapseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $blockT-color;
  background: $blockB-color;
  padding: 0 20px;
  border-radius: 5px;
  border: none;
  z-index: 1;
  position: relative;

    &__title {
      color: $blockT-color;
      font-size: 22px;
      font-weight: 700;
      margin-top: 5px;
      margin-bottom: 5px;

      @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        font-size: 18px;
      }
          
      @media (max-width: $breakpoint-mobile) {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }



    &__chevron {
      font-size: 35px;
      color: $blockT-color;
      padding: 0;
      margin-top: 5px;
      background: none;
      border: none;

      @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        font-size: 30px;
      }
            
      @media (max-width: $breakpoint-mobile) {
        font-size: 25px;
        margin-top: 5px;
      }
    }

    &__content {
      //min-height: 280px;
      text-align: left;
      color: $secondaryT-color;
      font-size: 18px;
      font-weight: 400;
      list-style: inside;
      padding: 20px 20px 20px 20px;
      background-color: $secondaryB-color;
      margin-top: -5px;
      margin-bottom: 0;
      border-radius: 5px;
      z-index: 0;
      position: relative;

      @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
          font-size: 14px;
          padding: 25px 15px 15px 15px;
          margin-top: -10px;
      }

      @media (max-width: $breakpoint-mobile) {
          font-size: 12px;
          padding: 20px 10px 10px 10px;
          margin-top: -10px;
          min-height: auto;
      }
    }

}

@import 'variables';

/* ****************** style pour formulaire de contact ************************ */
.formContact {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: 25%;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
    border: none;

    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
      margin-left: 20%;
      width: 60%;
    }
      
    @media (max-width: $breakpoint-mobile) {
      margin-left: 10%;
      width: 80%;
    }
  
      &__field {
        margin-bottom: 15px;
        width: 100%;
        padding: 10px;
        border: 1px solid $secondaryB-color;
        border-radius: 4px;
        font-family: $font-family-base;
        font-size: 14px;
        
        &--submit {
          background-color: $blockB-color;
          color:$blockT-color;
          border: 1px solid $blockB-color;
          padding: 10px;
          font-size: 14px;
          cursor: pointer;
          width: 100%;
        }

        &--submit:hover {
          color: $linkHover-color;
          opacity: 0.9;
        }
    }
}

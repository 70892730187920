/* ---------------------------- Style pour le composant CARROUSEL ---------------------------- */

@import 'variables';

.carousel {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 70vw;

    &__picture {
        width: 100%;
        //width: 70vw;
        height: auto;
        //height: 50vh;
        border-radius: 25px;
        object-fit: contain;
        border-top: 1px solid $tertiaryB-color;
        border-bottom: 1px solid $tertiaryB-color;
    }

    &__previousButton--active, &__nextButton--active {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $blockT-color;
        width: 100px;
        background-color: unset;
        border: none;
        padding: 20px;
        cursor: pointer;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            width: 60px;
            padding: 10px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            width: 24px;
            padding: 5px;
        }
    }

    &__previousButton--active {
        left: -30px;
        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            left: -17px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            left: -5px;
        }
    }
      
    &__nextButton--active {
        right: -15px;
        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            right: -10px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            right: 0px;
        }
    }

    &__previousButton--inactive, &__nextButton--inactive {
        display: none;
    }

    &__previousButton--icone, &__nextButton--icone {
        font-size: 5vw;
        background-color: $blockB-color;
        opacity: 0.4;
        border-radius: 5px;
    }

    &__slideIndex--active {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        color: $blockT-color;
        font-size: 16px;
        font-weight: 500;
        background-color: $blockB-color;
        opacity: 0.4;
        padding: 5px 10px 5px 10px;
        border-radius: 5px;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            font-size: 14px;
            padding: 4px 8px 4px 8px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            font-size: 11px;
            padding: 3px 5px 3px 5px;
        }
    }

    &__slideIndex--inactive {
        display: none;
    }
}

// Palette https://coolors.co (+ clair ... + foncé)
//
// #F0EAD2 Parchment (beige clair) RGB 240, 234, 210
// #DDE5B6 Tea green (vert clair) RGB 221, 229, 182
// #ADC178 Olivine (vert moyen) RGB 173, 193, 120
// #A98467 Chamoisee (marron moyen) RGB 169, 132, 103
// #6C584C Umber (marron foncé) RGB 108, 88, 76


$primaryT-color: #A98467;
$secondaryT-color: #6C584C;
$blockT-color: #FFFFFF; // White


$primaryB-color: #FFFFFF; // White
$secondaryB-color: #E9E8E3;
$tertiaryB-color: #A98467;
$blockB-color: #6C584C;

$linkHover-color: rgb(21, 199, 17);

$font-family-base: 'Montserrat', sans-serif;

$breakpoint-mobile: 640px;
$breakpoint-desktop: 1024px;

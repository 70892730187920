/* ****************** Style pour la page FICHE PROJET ********************** */

@import 'variables';

.info {
    display: flex;
    flex-direction: column;
    font-family: $font-family-base;
    font-weight: 500;
    margin: 76px 100px 30px 100px;

    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        margin: 60px 50px 15px 50px;
    }
        
    @media (max-width: $breakpoint-mobile) {
        margin: 45px 20px 10px 20px;
    }
}

/* -------------------------- CARROUSEL ------------------ */
.blockCarousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    height: 40vw;
}

/* ---------------------------- TITRE ET INFORMATIONS GENERALES --------------- */
.infoBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 5px;

    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        flex-direction: column;

    }
        
    @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
    }
}

.identification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
        font-size: 32px;
        color: $primaryT-color;
        margin: 0;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            font-size: 26px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            font-size: 18px;
        }
    }

    &__theme {
        flex-direction: row;
        justify-content: start;
        font-size: 24px;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            font-size: 16px;
        }
            
        @media (max-width: $breakpoint-mobile) {
            font-size: 14px;
        }
    }

    &__tags {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 10px;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            flex-wrap: wrap;
            row-gap: 0;
            
        }
            
        @media (max-width: $breakpoint-mobile) {
            flex-wrap: wrap;
            row-gap: 0;
        }
    
        &--tag {
            border: none;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            background: $blockB-color;
            color: $blockT-color;
            border-radius: 10px;
            padding: 3px 22px 3px 22px; 
        }
    }
}

/* -------------------------- TAGS ET RATING -------------------------- */
.additional {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 20%;
    gap: 30px;
    
    
    @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
        
    @media (max-width: $breakpoint-mobile) {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
}

/* ---------------------- INFORMATIONS DETAILLEES (Description et Equipements) ---------------------- */
.details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
        
    @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        margin-bottom: 0;
    }

    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: $breakpoint-mobile) {
            margin-bottom: 25px;
        }

        &__banner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: $blockT-color;
            background: $blockB-color;
            padding: 5px 20px;
            border-radius: 10px;
            font-size: 18px;
            font-weight: 700;
            border: none;
            z-index: 1;
            position: relative;

            @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
                font-size: 16px;
            }
                
            @media (max-width: $breakpoint-mobile) {
                font-size: 13px;
            }

            &--chevron {
                font-size: 35px;
                color: $blockT-color;
                padding: 0;
                background: none;
                border: none;

                @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
                    font-size: 30px;
                    color: $blockT-color;
                }
                    
                @media (max-width: $breakpoint-mobile) {
                    font-size: 23px;
                    color: $blockT-color;
                }
            }
        }

        &--description {
            min-height: 280px;
            color: $secondaryT-color;
            font-weight: 400;
            padding: 30px 20px 20px 20px;
            background-color: $secondaryB-color;
            margin-top: -10px;
            border-radius: 5px;
            z-index: 0;
            position: relative;

            @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
                font-size: 14px;
                padding: 25px 15px 15px 15px;
            }
                
            @media (max-width: $breakpoint-mobile) {
                font-size: 12px;
                padding: 20px 10px 10px 10px;
                min-height: auto;
            }
        }
        
        &--listEquipment {
            list-style: inside;
            min-height: 280px;
            padding: 30px 20px 20px 20px;
            background-color: $secondaryB-color;
            margin-top: -10px;
            border-radius: 5px;
            z-index: 0;
            position: relative;

            @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
                font-size: 14px;
                padding: 25px 15px 15px 15px;
            }
                
            @media (max-width: $breakpoint-mobile) {
                font-size: 12px;
                padding: 20px 10px 10px 10px;
                min-height: auto;
            }
        }
    }
}

@import 'variables';

.navCont {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    background-color: $primaryB-color;

    &__block {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;

        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @media (max-width: $breakpoint-mobile) {
          padding-left: 10px;
          padding-right: 10px;
        }

        &--link {
          font-family: $font-family-base;
          color: #6C584C;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          padding: 25px 10px 25px 10px;

          &:hover {
            background-color: $secondaryB-color;
          }

          &:active {
            background-color: $secondaryB-color;
          }

          @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
            padding: 20px 8px 20px 8px;
            font-size: 12px;
          }
          @media (max-width: $breakpoint-mobile) {
            padding: 15px 5px 15px 5px;
            font-size: 10px;
          }
      }

      &--linkB {
        font-family: $font-family-base;
        background-color: $blockB-color;
        color: $blockT-color;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        align-items: center;
        text-decoration: none;
        padding: 4px 10px 4px 10px;
        border-radius: 10px;
        
        @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {
          padding: 4px 10px 4px 10px;
          font-size: 12px;
        }
        @media (max-width: $breakpoint-mobile) {
          padding: 4px 10px 4px 10px;
          font-size: 10px;
        }

        &:hover {
          color: $linkHover-color;
        }
      }
    }    
}